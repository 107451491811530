import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// Lazy load the views
const Home = lazy(() => import("../Views/Home"));
const CareerTest = lazy(() => import("../Views/CareerTest"));
const MajorTest = lazy(() => import("../Views/MajorTest"));
const Apply = lazy(() => import("../Views/Apply"));
const Result = lazy(() => import("../Views/Result"));
const ResultUapply = lazy(() => import("../Views/ResultUapply"));
const GoogleForm = lazy(() => import("../Views/GoogleForm"));
const Success = lazy(() => import("../Views/Success"));
const Failed = lazy(() => import("../Views/Failed"));

// Loading component
const LoadingScreen = () => (
  <div className="loading-screen">
    <div className="spinner">Loading...</div>
  </div>
);

export default function AppRouter() {
  return (
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/career" element={<CareerTest />} />
          <Route path="/major-test" element={<MajorTest />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/test-results" element={<Result />} />
          <Route path="/google-form" element={<GoogleForm />} />
          <Route path="/:language/success" element={<ResultUapply />} />
          <Route path="/kw/booking/success" element={<Success />} />
          <Route path="/booking/failed" element={<Failed />} />
          {/* Handle undefined routes by redirecting to home or a 404 page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
